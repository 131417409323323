
import _ from 'lodash';
import React from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Nav, Navbar, NavbarBrand, UncontrolledDropdown, } from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faBars, faCalendarAlt, faCalendarCheck, faCalendarDay, faCalendarPlus, faCalendarWeek, faCar, faClock, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import logoAutonort from '../../assets/img/autonort.png';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getUsuarioLogin } from '../../helpers/common';

export default class Menu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			opciones:[],
		};
		this.baseState = this.state;
	}

	async componentDidMount() {
		let User = getUsuarioLogin();
		let IdTipoCliente = User.logeado ? User.IdTipoCliente : 0;


    let opciones = [];
	if(IdTipoCliente == 1){
		opciones.push({ icon: faCar, nombre_opcion: 'Mis Vehiculos', id_opcion: '0001', url_opcion:'/vehiculosclientes'  });
		opciones.push({ icon: faCalendarDay, nombre_opcion: 'Mis Citas', id_opcion: '0002', url_opcion:'/citas' });
	}
	if(IdTipoCliente == 2){
		opciones.push({ icon: faCalendarAlt, nombre_opcion: 'Citas x confirmar', id_opcion: '0003', url_opcion:'/citasasesor' });
	}	
	if(IdTipoCliente == 3){
		opciones.push({ icon: faCar, nombre_opcion: 'Mis Vehiculos', id_opcion: '0001', url_opcion:'/vehiculosclientes'  });
		opciones.push({ icon: faCalendarDay, nombre_opcion: 'Mis Citas', id_opcion: '0002', url_opcion:'/citas' });
		opciones.push({ icon: faCalendarAlt, nombre_opcion: 'Citas x confirmar', id_opcion: '0003', url_opcion:'/citasasesor' });
	}
    

    this.setState({
      opciones: opciones,
    });    
  }

	render() {
		return (
			<>
				{/* Main Sidebar Container */}
				<aside className="main-sidebar elevation-4 sidebar-light-danger">
					{/* Brand Logo */}
					<div style={{ opacity: '.8', width: '100%', textAlign: 'center', marginBottom:'30px' }}>
						<img src={logoAutonort} alt="Autonort SAC" style={{ width: '60%' }} />
					</div>
					{/* Sidebar */}
					<div className="sidebar">

						{/* Sidebar Menu */}
						<nav className="mt-2">
							<ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
								{/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
								{
                    _.map(this.state.opciones, (obj, index) => (
                      <ItemMenu
                        key={index}
                        nombreModulo={obj.nombre_opcion}
                        rutaModulo={obj.url_opcion}
                        activo={(obj.id_opcion === this.props.idOpcion)}
                        icon={obj.icon}
                      />
                    ))
                  }			
							</ul>
						</nav>
						{/* /.sidebar-menu */}
					</div>
					{/* /.sidebar */}
				</aside>

			</>
		);
	}
}

const ItemMenu = (props) => {
  return(
    <li className='nav-item'>
      <Link className={(props.activo) ? 'nav-link active' : 'nav-link'} style={{ cursor: 'pointer', }} to={props.rutaModulo}>
        <Icon className='nav-icon' icon={props.icon} />
        <p>{props.nombreModulo}</p>
      </Link>
    </li>
  );
}