import React from 'react';
import _ from 'lodash';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Nav, Navbar, NavbarBrand, UncontrolledDropdown, } from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faBars, faClock, faSignOutAlt, faTimesCircle, faUser } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getUsuarioLogin } from '../../helpers/common';


export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nombreusuario: '',
      modalCerrarSesion: false
    };
    this.baseState = this.state;
  }

  componentDidMount() {
    let usuario = getUsuarioLogin();
    if (usuario.logeado) {
      this.setState({
        nombreusuario: usuario.display
      })
    }
    //let user = localStorage.getItem('usuario');
    // if(!_.isNull(user)){
    //   let usuario = JSON.parse(user);
    //   let name = usuario.RazonSocial === '' ? usuario.Nombres + ' ' + usuario.Apellidos : usuario.RazonSocial;
    //   this.setState({
    //     nombreusuario: name,
    //   })
    // }
  }

  render() {
    return (
      <>
        {/* Navbar */}
        <Navbar color='danger' light expand='md' className="main-header navbar navbar-expand navbar-dark navbar-danger">
          {/* Left navbar links */}
          <Nav navbar>
            <li className="nav-item">
              <a className="nav-link" data-widget="pushmenu" href="#" role="button"><Icon icon={faBars}></Icon></a>
            </li>
          </Nav>
          <Nav navbar className="ml-auto">
            <UncontrolledDropdown nav color="primary">
              <DropdownToggle className='px-1' nav caret>
                <Icon icon={faUser} />{this.state.nombreusuario}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={() => this.setState({ modalCerrarSesion: true, })}>
                  <a><Icon icon={faSignOutAlt} /> Cerrar sesión</a>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Navbar>
        {/* /.navbar */}
        <ModalCerrarSesion
          abrir={this.state.modalCerrarSesion}
          onCancelar={() => this.setState({ modalCerrarSesion: false, })}
        />
      </>
    );
  }
}

const ModalCerrarSesion = (props) => {
  return (
    <Modal isOpen={props.abrir} backdrop='static' keyboard={false}>
      <ModalBody>
        <p>¿Está seguro(a) que desea cerrar sesión?</p>
      </ModalBody>
      <ModalFooter style={{ display: 'block', }}>
        <div className='clearfix'>
          <Button
            className='float-left'
            color='secondary'
            onClick={() => props.onCancelar()}
          >
            <Icon icon={faTimesCircle} /> Cancelar
          </Button>

          <Link to='/'>
            <Button
              className='float-right'
              color='primary'
            >
              <Icon icon={faSignOutAlt} /> Cerrar sesión
            </Button>
          </Link>
        </div>
      </ModalFooter>
    </Modal>
  )
}