import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Label, Row, Table } from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus, faCar, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Content, Header, Menu, Footer } from '../base';
import CardCita from './cardCita';
import ModalCancelarCita from './modalCancelarCita';
import ModalConfirmarCita from './modalConfirmarCita';
import _ from 'lodash';
import moment from 'moment';
import { CitaStore } from '../../stores';
import { LoaderFull } from '../../helpers/ui';

//1=Por confirmar, 2=Confirmado, 3=Anulado, 4=Realizado

export default class ListaVehiculos extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lstCitas: [],
			iniciando: false,
		};

		this.baseState = this.state;
	}

	componentDidMount() {
		this.cargarCitas();
	}

	async cargarCitas() {
		this.setState({ iniciando: true });
		let req = await CitaStore.getCitasAsesor();
		if (req.exito) {
			this.setState({ lstCitas: req.datos, iniciando: false })
		}
	}

	clickCitas(id, placa) {
		alert(placa);
	}

	clickInfo(id, placa) {

	}

	render() {
		return (
			<>
				<Header ></Header>
				<Menu idOpcion='0003'></Menu>
				<Content titlePage={'Lista de citas x confirmar'}>
					<Card>
						<CardBody>
							{
								(this.state.iniciando) ?
									<LoaderFull text='Cargando citas...' />
									:
									<Table responsive>
										<thead>
											<tr>
												<th>Cliente</th>
												<th>DNI/RUC</th>
												<th>Celular</th>
												<th>Email</th>
												<th>Placa</th>
												<th>Fecha</th>
												<th>Turno</th>
												<th>Tipo Mant.</th>
												<th>Estado</th>
												<th>Acciones</th>
											</tr>
										</thead>
										<tbody>
											{
												_.map(this.state.lstCitas, (obj, index) => (
													<>
														<tr>
															<td>{obj.NomCliente + ' ' + obj.ApeCliente}</td>
															<td>{obj.NroDocumento}</td>
															<td>{obj.Telefono}</td>
															<td>{obj.Email}</td>
															<td>{obj.PlacaVh}</td>
															<td>{moment(obj.FechaCita).format('DD/MM/YYYY')}</td>
															<td>{obj.Turno}</td>
															<td>{obj.TipoMantenimiento}</td>
															<td>{obj.Estado}</td>
															<td>
																{(obj.IdEstado == 1 || obj.IdEstado == 2) &&
																	<><ModalCancelarCita idCita={obj.IdCita} placa={obj.PlacaVh} callBack={() => this.cargarCitas()} /> {' '} </>
																}
																{(obj.IdEstado == 1) &&
																	<ModalConfirmarCita idCita={obj.IdCita} placa={obj.PlacaVh} callBack={() => this.cargarCitas()} />
																}
															</td>
														</tr>
													</>
												))
											}

										</tbody>
									</Table>
							}
						</CardBody>
					</Card>
				</Content>
			</>
		);
	}
}
