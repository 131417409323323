import { Peticion, } from '../helpers/common/index';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export default class CitaStore {

	static getClienteNisiraXDni(Dni) {
		const req = new Peticion();
		return req.get(
			`${API_BASE_URL}api/cliente/obtenerClienteNisiraXDni?Dni=${Dni}`
		);
	}

	static getClienteNisiraXRuc(Ruc) {
		const req = new Peticion();
		return req.get(
			`${API_BASE_URL}api/cliente/obtenerClienteNisiraXRuc?Ruc=${Ruc}`
		);
	}

	static confirmarCita(obj) {
		const req = new Peticion();
		return req.post(
			`${API_BASE_URL}api/cita/confirmarCita/`,
			obj
		);
	}

	static crearUsuario(obj) {
		const req = new Peticion();
		return req.post(
			`${API_BASE_URL}api/cliente/crearUsuario/`,
			obj
		);
	}
	
	static iniciarSession(obj) {
		const req = new Peticion();
		return req.post(
			`${API_BASE_URL}api/cliente/iniciarSession/`,
			obj
		);
	}
}