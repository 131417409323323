import React from 'react';
import { Button, Card, CardBody, Col, Label, Modal, Row } from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faCalendarDay, faCalendarPlus, faCar, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ModalCancelarCita from './modalCancelarCita';


export default class CardCita extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };

    this.baseState = this.state;
  }
  render() {
    return (
      <>
        <Col xs={6} sm={4} md={3} lg={2}  >
          <Card>
            <CardBody>
              <Row >
                <Col md={12} className="text-center" style={{ fontSize: '50px', color: 'grey' }}><Icon icon={faCalendarDay}></Icon></Col>
              </Row>
              <Row>
                <Col md={12} className="text-center" style={{ fontSize: '20px', color: 'grey', fontWeight: 'bold' }} ><Label>{this.props.placa}</Label></Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} className="text-center"><Label>{this.props.fecha}</Label></Col>                
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} className="text-center"><Label>{this.props.turno}</Label></Col>                
              </Row>
              <Row>
                <Col xs={6} sm={6} md={6} lg={6} className="text-center"><ModalCancelarCita idCita={this.props.idCita} placa={this.props.placa} callBack={this.props.callBack}></ModalCancelarCita></Col>
                <Col xs={6} sm={6} md={6} lg={6} className="text-center"><Button disabled={true} color="primary" onClick={(e) => this.props.informacion(this.props.id, this.props.placa)}><Icon icon={faInfoCircle}></Icon></Button></Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}