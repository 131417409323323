import React from 'react';
import { Button, Card, CardBody, Col, Label, Modal, Row } from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus, faCar, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ModalRegistrarCita from '../citas/modalRegistrarCita';


export default class CardVehiculo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };

    this.baseState = this.state;
  }
  render() {
    return (
      <>
        <Col xs={6} sm={4} md={3} lg={2}  >
          <Card>
            <CardBody>
              <Row >
                <Col md={12} className="text-center" style={{ fontSize: '50px', color: 'grey' }}><Icon icon={faCar}></Icon></Col>
              </Row>
              <Row>
                <Col md={12} className="text-center" style={{ fontSize: '20px', color: 'grey', fontWeight: 'bold' }} ><Label>{this.props.placa}</Label></Col>
              </Row>
              <Row>
                <Col xs={6} sm={6} md={6} lg={6} className="text-center">
                  <ModalRegistrarCita 
                    placa={this.props.placa}
                    idVehiculo={this.props.idVehiculo}
                    tieneReservas={Boolean(parseInt(this.props.nroReservas) > 0)}
                    cargarVehiculos={this.props.cargarVehiculos}
                  ></ModalRegistrarCita>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} className="text-center">
                  <Button disabled={true} color="primary" onClick={(e) => this.props.informacion(this.props.id, this.props.placa)}>
                    <Icon icon={faInfoCircle}></Icon>
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}