import React from 'react';
import {
  Spinner,
} from 'reactstrap';

export default class LoaderFull extends React.Component {
  render() {
    return (
      <div className={'text-center'} style={{ paddingTop: '80px', paddingBottom: '80px'}}>
        <Spinner color='primary' />
        {
          (this.props.text) ?
            <p><small>{this.props.text}</small></p>
          :
            null
        }
      </div>
    );
  }
}