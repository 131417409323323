import React from 'react';
import {
  Button, Spinner
} from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

export default class CustomButton extends React.Component {
  render() {
    const { visible, loading, icon, text, ...rest } = this.props

    if (visible === false)
      return null;
    else
      return (
        <Button {...rest}>
          {
            (loading === true) ?
              <>
                <Spinner size="sm" color="light" />{' '}{this.props.text}
              </>
            : (icon != null || icon != undefined) ?
              <>
                <Icon icon={icon} />{' '}{this.props.text}
              </>
            :
              this.props.text
          }
        </Button>
      )
  }
}