import React from 'react';
import { $, jQuery, } from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'admin-lte/dist/css/adminlte.min.css';
import 'admin-lte/dist/js/adminlte.min';
import "font-awesome/css/font-awesome.css";
import "tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.css";
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from './routes/routes';
import moment from 'moment';


export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      variable:'',
    };

    this.baseState = this.state;
  }
  render() {
    moment().locale('es');
    return (
        <BrowserRouter children={Routes} basename={'/'} />
    );
  }  
}
