import { faCheckCircle, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { FormGroup, Input, Label } from 'reactstrap';
import logoautonort from '../../assets/img/autonort.png';
import CustomButton from '../../helpers/ui/customButton';
import { ClienteStore } from '../../stores';

export default class Login extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			usuario: '',
			contrasenia: '',
			procesando: false,
			estadoInicio: 0,
			IdTipoCliente:0,
		}

		this.baseState = this.state;
	}

	async procesar() {
		this.setState({ procesando : true});
		let req = await ClienteStore.iniciarSession({ Usuario: this.state.usuario, Contrasenia: this.state.contrasenia });
		if (req.exito) {
			if(!_.isNull(req.datos))
			{
				localStorage.setItem('usuario', JSON.stringify(req.datos));
				this.setState({
					estadoInicio:1,
					procesando:false,
					IdTipoCliente: req.datos.IdTipoCliente,
				})
			}
			else
			{
				this.setState({
					estadoInicio:2,
					procesando:false,
				})
			}
		}
	}

	render() {
		return (
			<div className='login-page'>
				<div className='login-box' style={{ marginTop: '-200px', }}>
					<div className='login-logo'>
						<img className='img-fluid' src={logoautonort} style={{ width: '200px', }} />
					</div>
					<div className='card'>
						<div className='card-body login-card-body'>
							<h5 className='login-box-msg'>Sistema de Citas</h5>
							{/* <div className='login-logo'>
								<img className='img-fluid' style={{ maxHeight: '100px', }} src={logoautonort} />
							</div> */}
							{
								(this.state.estadoInicio == 1) &&
								

								
								<Redirect
									from="/"
									to={{
										pathname: (this.state.IdTipoCliente == 2) ? "/citasasesor" : "/vehiculosclientes" ,
										//state: { usuario: clienteNisira, xRuc: this.state.xRuc }
									}}>
								</Redirect>
							}
							<FormGroup >
								<Label>Usuario:</Label>
								<Input type="text" onChange={(e) => this.setState({ usuario: e.target.value })}></Input>
							</FormGroup>
							<FormGroup >
								<Label>Contraseña:</Label>
								<Input type="password" onChange={(e) => this.setState({ contrasenia: e.target.value })}></Input>
							</FormGroup>
							{/* <button
                className='btn btn-danger btn-block'
                onClick={ ()=>this.props.history.push('/resumen') }
              >
                Ingresar
              </button> */}
							<div class="social-auth-links text-center mb-3">
								<CustomButton
									className='btn-block'
									color='danger'
									icon={faLockOpen}
									text='Ingresar'
									loading={this.state.procesando}
									disabled={this.state.procesando}
									onClick={() => this.procesar()}
								/>
							</div>
							<p class="mb-1">
								<a href="busquedacliente" class="text-center">No tengo usuario, Registrarme</a>
							</p>
							{
								(this.state.estadoInicio == 2) &&
								<FormGroup >
								<Label>Usuario o contraseña no validos</Label>
							</FormGroup>
							}
						</div>
					</div>
				</div>
			</div>
		);
	}
}