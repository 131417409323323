import _ from 'lodash';
import React from 'react';
import {
	Container, Row, Col,
	Form, FormGroup, Label, Input, FormFeedback, InputGroup, InputGroupAddon, InputGroupText,
	Modal, ModalHeader, ModalBody, ModalFooter,
	Table,
	Button,
} from 'reactstrap';
import { faUpload, faTimesCircle, faCalendarPlus, faCheckCircle, faCalendarMinus, faTrash, faTrashAlt, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import CustomButton from '../../helpers/ui/customButton';
import {
	DateTimePicker,
	DatePicker,
	TimePicker,
	MonthPicker,
	InlineDateTimePicker,
} from "react-tempusdominus-bootstrap";
import moment from 'moment-timezone';
import { CitaStore } from '../../stores';


export default class ModalCancelarCita extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			procesando:false,
		};
		this.baseState = this.state;
	}

	async procesar(){
		this.setState({ procesando:true});
		let req = await CitaStore.cancelarCita({IdCita: parseInt(this.props.idCita)});
		if(req.exito)
		{
			this.props.callBack();
			this.setState(this.baseState);
		}
	}

	render() {
		const { visible, } = this.props;
		const { modal, } = this.state;

		return (
			<>
				<Button  color="danger" onClick={() => this.setState({ modal: true, })}>
					<Icon icon={faTrash}></Icon>
				</Button>
				<Modal size='md' isOpen={modal} unmountOnClose={true} backdrop='static' keyboard={false} autoFocus={false}>
					<ModalHeader className='ModalHeader' toggle={() => this.setState(this.baseState)}>
						{'Cancelar Cita de Vehiculo ' + this.props.placa}
					</ModalHeader>
					<ModalBody className='ModalBody'>
						<Container fluid>
							<FormGroup>
								<Label >¿Desea Cancelar la cita?</Label>
							</FormGroup>
						</Container>
					</ModalBody>
					<ModalFooter className='ModalFooter'>
						<div className='m-0 clearfix-widthfull '>
							<CustomButton
								className='float-letf'
								color='secondary'
								icon={faTimesCircle}
								text='Cancelar'
								disabled={this.state.procesando}
							  onClick={()=>this.setState(this.baseState)}
							/>
							<CustomButton
								className='float-right'
								color='danger'
								icon={faTrashAlt}
								text='Cancelar Cita'
								loading={this.state.procesando}
								disabled={this.state.procesando}
								onClick={()=>this.procesar()}
							/>
						</div>
					</ModalFooter>
				</Modal>
			</>
		);
	}
}