import * as React from 'react';
import { Route, Switch, } from 'react-router-dom';
import { VehiculosCliente, Citas, CitasAsesor, Login, Registro, BusquedaCliente } from '../componentes';


export const Routes = (
  <>
    <Switch>      
      <Route exact={true} path='/' component={Login} />
      <Route exact={true} path='/busquedacliente' component={BusquedaCliente} />
      <Route exact={true} path='/registro' component={Registro} />
      <Route exact={true} path='/vehiculosclientes' component={VehiculosCliente} />
      <Route exact={true} path='/citas' component={Citas} />
      <Route exact={true} path='/citasasesor' component={CitasAsesor} />
    </Switch>
  </>
);