import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Label, Row } from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus, faCar, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Content, Header, Menu, Footer } from '../base';
import CardVehiculo from './cardVehiculo';
import { VechiculoStore } from '../../stores';
import { getUsuarioLogin } from '../../helpers/common';
import { LoaderFull } from '../../helpers/ui';



export default class ListaVehiculos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nombres: '',
      apellidos: '',
      direccion: '',
      lstVehiculos: [],
      iniciando:false,
    };

    this.baseState = this.state;
  }

  componentDidMount() {
    this.cargarVehiculos();
  }

  async cargarVehiculos() {
    this.setState({iniciando:true });
    let User = getUsuarioLogin();
    let IdCliente = User.logeado ? User.IdCliente : 0;

    let req = await VechiculoStore.getVehiculos(IdCliente);
    if (req.exito) {
      this.setState({ lstVehiculos: req.datos, iniciando:false })
    }
  }

  clickInfo(id, placa) {

  }

  render() {
    return (
      <>
        <Header ></Header>
        <Menu idOpcion='0001'></Menu>
        <Content titlePage={'Mis Vehiculos'}>
          {
            (this.state.iniciando) ?
              <LoaderFull text='Cargando vehiculos...' />
              :
              <Row>
                {
                  _.map(this.state.lstVehiculos, (obj, index) => (
                    <>
                      <CardVehiculo
                        placa={obj.Placa}
                        idVehiculo={obj.IdVehiculo}
                        nroReservas={obj.Reservas}
                        cargarVehiculos={() => this.cargarVehiculos()}
                        informacion={(id, placa) => this.clickInfo(id, placa)}
                      />
                    </>
                  ))
                }

              </Row>
          }
        </Content>
      </>
    );
  }
}
