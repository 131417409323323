import React from 'react';
import {
	Container
} from 'reactstrap';

export default class Footer extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<>
				<footer className="main-footer">
					<strong>Copyright © 2021 <a href="#">www.autnort.com.pe</a>.</strong>
        All rights reserved.
        <div className="float-right d-none d-sm-inline-block">
						<b>Version</b> 1.0.0
        </div>
				</footer>

			</>
		);
	}
}


