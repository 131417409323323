/* validaciones.jsx */

export function validarNroDNI(numeroDNI) {
  return (/^[0-9]{8}$/.test(numeroDNI));
}

export function validarNroRUC(numeroRUC) {
  return (/^[1-2]{1}[0-9]{10}$/.test(numeroRUC));
}

export function validarEmail(direccionEmail) {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(direccionEmail).toLowerCase());
}

export function validarNroCelular(numeroCelular) {
  return /^9[0-9]{8}$/.test(numeroCelular);
}

export function validarNro(numero) {
  return (/^[0-9]{1,10}$/.test(numero));
}

export function validarNroDecimal(numero) {
  if (numero !== "") {
    return (/^\d*\.?\d*$/.test(numero));
  }
  else { return false; }
}

export function validarPassword(pass) {
  let valid = false;
  if (pass.length >= 8) { 
    let regex = /^(?=.*\d)(?=.*[a-záéíóúüñ]).*[A-ZÁÉÍÓÚÜÑ]/;
    valid = regex.test(pass);
  }  
  return valid;
}