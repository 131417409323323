import { faCheckCircle, faLockOpen, faSave, faSearch } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { FormFeedback, FormGroup, Input, Label, InputGroup } from 'reactstrap';
import logoautonort from '../../assets/img/autonort.png';
import CustomButton from '../../helpers/ui/customButton';
import { ClienteStore } from '../../stores';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

export default class BusquedaCliente extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			ruc: '',
			dni: '',
			procesando: false,
			clienteNisira: {},
			existe: false,
			consultado: false,
			xRuc: true,
		}

		this.baseState = this.state;
	}

	async cargarClienteXDni() {
		this.setState({ procesando: true });
		let req = await ClienteStore.getClienteNisiraXDni(this.state.dni);
		if (req.exito) {
			if (req.datos.length > 0) {

				//this.props.history.push('/home');
				this.setState({
					clienteNisira: req.datos[0],
					// nombres: req.datos[0].NOMBRES,
					// apellidos: req.datos[0].APELLIDOPATERNO + ' ' + req.datos[0].APELLIDOMATERNO,
					// mail: req.datos[0].E_MAIL,
					// telefono: req.datos[0].TELEFONO_1,
					// razonSocial: req.datos[0].RAZON_SOCIAL,
					// usuario: req.datos[0].IDCLIEPROV,
					consultado: true,
					existe: true,
					xRuc: false,
				});
			}
			else {
				this.setState({ clienteNisira: req.datos, consultado: true, existe: false, procesando: false });
				console.log(this.state)
			}

		}
	}

	async cargarClienteXRuc() {
		this.setState({ procesando: true });
		let req = await ClienteStore.getClienteNisiraXRuc(this.state.ruc);
		if (req.exito) {
			if (req.datos.length > 0) {

				//this.props.history.push('/home');
				this.setState({
					clienteNisira: req.datos[0],
					// nombres: req.datos[0].NOMBRES,
					// apellidos: req.datos[0].APELLIDOPATERNO + ' ' + req.datos[0].APELLIDOMATERNO,
					// mail: req.datos[0].E_MAIL,
					// telefono: req.datos[0].TELEFONO_1,
					// razonSocial: req.datos[0].RAZON_SOCIAL,
					// usuario: req.datos[0].IDCLIEPROV,
					consultado: true,
					existe: true,
					xRuc: true,
				});
			}
			else {
				this.setState({ clienteNisira: req.datos, consultado: true, existe: false, procesando: false });
				console.log(this.state)
			}

		}
	}

	render() {
		let clienteNisira = this.state.clienteNisira;
		return (
			<div className='login-page'>
				<div className='login-box' style={{ marginTop: '-200px', }}>
					<div className='login-logo'>
						<img className='img-fluid' src={logoautonort} style={{ width: '200px', }} />
					</div>
					<div className='card'>
						<div className='card-body login-card-body'>
							<h5 className='login-box-msg'>Registro de usuarios</h5>

							<FormGroup  >
								<Label>Ruc:</Label>
								<Input type="text" onChange={(e) => this.setState({ ruc: e.target.value })}></Input>
							</FormGroup>
							<div class="social-auth-links text-center mb-3">
								<CustomButton
									className='btn-block'
									color='success'
									icon={faSearch}
									text='Buscar por RUC'
									loading={this.state.procesando}
									disabled={this.state.procesando}
									onClick={() => this.cargarClienteXRuc()}
								/>
							</div>
							<FormGroup >
								<Label>Dni:</Label>
								<Input type="text" onChange={(e) => this.setState({ dni: e.target.value })}></Input>
							</FormGroup>
							<div class="social-auth-links text-center mb-3">
								<CustomButton
									className='btn-block'
									color='primary'
									icon={faSearch}
									text='Buscar por DNI'
									loading={this.state.procesando}
									disabled={this.state.procesando}
									onClick={() => this.cargarClienteXDni()}
								/>
							</div>
							{
								(this.state.consultado) &&
								<>
									{
										(this.state.existe === false) ?
											<>
												<FormGroup >
													<Label>Cliente no existe</Label>
												</FormGroup>
											</>
											:
											<Redirect
												from="/busquedacliente"
												to={{
													pathname: "/registro",
													state: { cliente: clienteNisira, xRuc: this.state.xRuc }
												}}>
											</Redirect>
									}
								</>
							}
						</div>
					</div>
				</div>
			</div>
		);
	}
}