import { Peticion, } from '../helpers/common/index';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export default class VehiculoStore {


  static getProcesosPorFiltro(porFechaSTD, fechaSTDInicio, fechaSTDFin, porEstado, estado) {
    const req = new Peticion();
    return req.get(
      `${API_BASE_URL}api/proceso/porFiltro/?porFechaSTD=${porFechaSTD}&fechaSTDInicio=${fechaSTDInicio}&fechaSTDFin=${fechaSTDFin}&porEstado=${porEstado}&estado=${estado}`
    );
  }  

  static getVehiculos(idCliente) {
    const req = new Peticion();
    return req.get(
      `${API_BASE_URL}api/vehiculo/porCliente/?idCliente=${idCliente}`
    );
  } 

  static reguFileCotizador(obj) {
    const req = new Peticion();
    return req.post(
      `${API_BASE_URL}api/proceso/reguFileCotizador/`,
      obj
    );
  }  
}
