import { faArrowAltCircleLeft, faBackspace, faCheckCircle, faLockOpen, faSave, faSearch } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { FormFeedback, FormGroup, Input, Label, InputGroup, Col } from 'reactstrap';
import logoautonort from '../../assets/img/autonort.png';
import CustomButton from '../../helpers/ui/customButton';
import { ClienteStore } from '../../stores';
import { validarEmail, validarPassword } from '../../helpers/common';


export default class Registro extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			ruc: '',
			dni: '',
			procesando: false,
			clienteNisira: {},
			existe: false,
			consultado: false,
			razonSocial: '',
			nombres: '',
			apellidos: '',
			mail: '',
			telefono: '',
			password1: '',
			password2: '',
			usuario: '',
			xRuc: true,
			grabado: false,
			procesando: false,
			idClieProv:'',
		}

		this.baseState = this.state;
	}

	componentDidMount() {
		let cliente = this.props.location.state.cliente;
		let xRuc = this.props.location.state.xRuc;
		this.setState({
			clienteNisira: cliente,
			nombres: _.trimEnd(_.trimStart(cliente.NOMBRES)),
			apellidos: _.trim(cliente.APELLIDOPATERNO) + ' ' + _.trim(cliente.APELLIDOMATERNO),
			mail: _.trim(cliente.E_MAIL),
			telefono: _.trim(cliente.TELEFONO_1),
			razonSocial: _.trimEnd(_.trimStart(cliente.RAZON_SOCIAL)),
			usuario: _.trim(cliente.USUARIO),
			idClieProv: cliente.IDCLIEPROV,
			xRuc: xRuc,
		});
	}

	async procesar() {

		if (this.IsValido()) {
			this.setState({ procesando: true });
			let req = await ClienteStore.crearUsuario({
				IdCliente: 0,
				IdTipoCliente: 1,
				IdClieProv: this.state.idClieProv,
				RazonSocial: this.state.razonSocial,
				Nombre: this.state.nombres,
				Apellido: this.state.apellidos,
				NroDocumento: this.state.usuario,
				Telefono: this.state.telefono,
				Email: this.state.mail,
				Usuario: this.state.usuario,
				Contrasenia: this.state.password1,
				Estado: 2
			});

			if (req.exito) {
				this.setState({ grabado: true });
			}
			else
			{
				this.setState({ procesando: false });
			}

		}
	}

	IsValido() {
		if (this.state.xRuc) {
			if (
				this.validmail(this.state.mail)
				&& this.validarContra(this.state.password1, this.state.password2)
				&& this.validarContraFormat(this.state.password1)
				&& Boolean(this.state.razonSocial)
				&& Boolean(this.state.telefono)
			) {
				return true;
			}
			else {
				return false
			}
		}
		else {
			if (
				this.validmail(this.state.mail)
				&& this.validarContra(this.state.password1, this.state.password2)
				&& this.validarContraFormat(this.state.password1)
				&& Boolean(this.state.nombres)
				&& Boolean(this.state.apellidos)
				&& Boolean(this.state.telefono)
			) {
				return true;
			}
			else {
				return false
			}
		}
	}

	validmail(mail) {
		return validarEmail(mail) && _.trim(mail) !== "";
	}

	validarContra(pass1, pass2) {
		let valid = true;
		if (pass1 != pass2) {
			valid = false;
		}
		return valid;
	}

	validarContraFormat(pass) {
		return validarPassword(pass);
	}

	render() {
		return (
			<div className='login-page'>
				<div className='login-box' style={{ marginTop: '0px', }}>
					<div className='login-logo'>
						<img className='img-fluid' src={logoautonort} style={{ width: '200px', }} />
					</div>
					<div className='card'>
						<div className='card-body login-card-body'>
							<h5 className='login-box-msg'>Registro de usuarios</h5>
							{
								(this.state.grabado) ?
									<>
										<FormGroup row>
											<Label sm={12}>Se creo el usuario de manera correcta</Label>
										</FormGroup>
										<div class="social-auth-links text-center mb-3">
											<Link to="/">
												<CustomButton
													className='btn-block'
													color='success'
													icon={faArrowAltCircleLeft}
													text='Ir a login'
												/>
											</Link>
										</div>
									</>
									:
									(this.state.xRuc) ?
										<>
											<FormGroup row>
												<Label sm={4}>R. Social:</Label>
												<Col sm={8}>
													<Input disabled={this.state.procesando} invalid={!Boolean(this.state.razonSocial)} type="text" value={this.state.razonSocial} onChange={(e) => this.setState({ razonSocial: e.target.value })}></Input>
													<FormFeedback>{'Razon Social es un campo necesario'}</FormFeedback>
												</Col>
											</FormGroup>
											<FormGroup row>
												<Label sm={4}>Telefono:</Label>
												<Col sm={8}>
													<Input disabled={this.state.procesando} invalid={!Boolean(this.state.telefono)} type="text" value={this.state.telefono} onChange={(e) => this.setState({ telefono: e.target.value })}></Input>
													<FormFeedback>{'Telefono es un campo necesario'}</FormFeedback>
												</Col>
											</FormGroup>
											<FormGroup row>
												<Label sm={4}>Mail:</Label>
												<Col sm={8}>
													<Input disabled={this.state.procesando} invalid={!this.validmail(this.state.mail)} type="text" value={this.state.mail} onChange={(e) => this.setState({ mail: e.target.value })}></Input>
													<FormFeedback>{'Mail es un campo necesario'}</FormFeedback>
												</Col>
											</FormGroup>
											<FormGroup >
												<Label>Usuario:</Label>
												<Input disabled={this.state.procesando} type="text" disabled={true} value={this.state.usuario} onChange={(e) => this.setState({ usuario: e.target.value })}></Input>
											</FormGroup>
											<FormGroup >
												<Label>Contraseña:</Label>
												<Input disabled={this.state.procesando} invalid={!this.validarContraFormat(this.state.password1)} type="password" value={this.state.password1} onChange={(e) => this.setState({ password1: e.target.value })}></Input>
												<FormFeedback>{'por lo menos un numero, una minúscula, una mayuscula y mas de 8 caracteres'}</FormFeedback>
											</FormGroup>
											<FormGroup >
												<Label>Confirmar Contraseña:</Label>
												<Input disabled={this.state.procesando} invalid={!this.validarContra(this.state.password1, this.state.password2)} type="password" value={this.state.password2} onChange={(e) => this.setState({ password2: e.target.value })}></Input>
												<FormFeedback>{'Las contraseñas deben de ser iguales'}</FormFeedback>
											</FormGroup>
											<div class="social-auth-links text-center mb-3">
												<CustomButton
													className='btn-block'
													color='danger'
													icon={faSave}
													text='Registrar'
													loading={this.state.procesando}
													disabled={this.state.procesando}
													onClick={() => this.procesar()}
												/>
											</div>
										</>
										:
										<>
											<FormGroup row>
												<Label sm={4}>Nombres:</Label>
												<Col sm={8}>
													<Input disabled={this.state.procesando} type="text" value={this.state.nombres} onChange={(e) => this.setState({ nombres: e.target.value })}></Input>
												</Col>
											</FormGroup>
											<FormGroup row>
												<Label sm={4}>Apellidos:</Label>
												<Col sm={8}>
													<Input disabled={this.state.procesando} type="text" value={this.state.apellidos} onChange={(e) => this.setState({ apellidos: e.target.value })}></Input>
												</Col>
											</FormGroup>
											<FormGroup row>
												<Label sm={4}>Telefono:</Label>
												<Col sm={8}>
													<Input disabled={this.state.procesando} invalid={!Boolean(this.state.telefono)} type="text" value={this.state.telefono} onChange={(e) => this.setState({ telefono: e.target.value })}></Input>
													<FormFeedback>{'Telefono es un campo necesario'}</FormFeedback>
												</Col>
											</FormGroup>
											<FormGroup row>
												<Label sm={4}>Mail:</Label>
												<Col sm={8}>
													<Input disabled={this.state.procesando} invalid={!this.validmail(this.state.mail)} type="text" value={this.state.mail} onChange={(e) => this.setState({ mail: e.target.value })}></Input>
													<FormFeedback>{'Mail es un campo necesario'}</FormFeedback>
												</Col>
											</FormGroup>
											<FormGroup >
												<Label>Usuario:</Label>
												<Input disabled={this.state.procesando} type="text" disabled={true} value={this.state.usuario} onChange={(e) => this.setState({ usuario: e.target.value })}></Input>
											</FormGroup>
											<FormGroup >
												<Label>Contraseña:</Label>
												<Input disabled={this.state.procesando} invalid={!this.validarContraFormat(this.state.password1)} type="password" value={this.state.password1} onChange={(e) => this.setState({ password1: e.target.value })}></Input>
												<FormFeedback>{'por lo menos un numero, una minúscula, una mayuscula y mas de 8 caracteres'}</FormFeedback>
											</FormGroup>
											<FormGroup >
												<Label>Confirmar Contraseña:</Label>
												<Input disabled={this.state.procesando} invalid={!this.validarContra(this.state.password1, this.state.password2)} type="password" value={this.state.password2} onChange={(e) => this.setState({ password2: e.target.value })}></Input>
												<FormFeedback>{'Las contraseñas deben de ser iguales'}</FormFeedback>
											</FormGroup>
											<div class="social-auth-links text-center mb-3">
												<CustomButton
													className='btn-block'
													color='danger'
													icon={faSave}
													text='Registrar'
													loading={this.state.procesando}
													disabled={this.state.procesando}
													onClick={() => this.procesar()}
												/>
											</div>
										</>
							}
						</div>
					</div>
				</div>
			</div>
		);
	}
}