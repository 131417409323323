import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import {
  Container, Row, Col,
  Form, FormGroup, Label, Input, FormFeedback, InputGroup, InputGroupAddon, InputGroupText,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Table,
  Button,
} from 'reactstrap';
import { faUpload, faTimesCircle, faCalendarPlus, faCheckCircle, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import CustomButton from '../../helpers/ui/customButton';
import { CitaStore } from '../../stores';
import { getUsuarioLogin } from '../../helpers/common';
//import DatePicker  from 'reactstrap-date-picker';
import { DatePicker } from 'react-tempusdominus-bootstrap';




export default class ModalRegistrarCita extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      procesando: false,
      modal: false,
      idTipoMantenimiento: 1,
      idTurno: 1,
      Kilometraje: '',
      lstTipoMante: [],
      lstTurnos: [],
      fechaMin: moment().subtract(-2, 'days'),
      fecha: moment().subtract(-2, 'days'),
    };
    this.baseState = this.state;
  }


  async cargarModal() {
    this.setState({ modal: true, procesando: true })

    let req = await CitaStore.getTiposMantenimientos();
    if (req.exito) {
      this.setState({ lstTipoMante: req.datos });
    }

    //this.cargarTurnos(this.state.fecha);
  }

  cambiarFecha(fecha) {
    //this.cargarTurnos(e.date);       
    this.cargarTurnos(fecha.date);

    this.setState({ fecha: fecha.date });
  }

  async cargarTurnos(date) {
    let req2 = await CitaStore.getTurnosXFecha(moment(date).format('YYYY-DD-MM'));
    if (req2.exito) {
      this.setState({ lstTurnos: req2.datos, fecha: date, procesando:false });
    }
  }

  async procesar() {
    this.setState({ procesando:true});
    let User = getUsuarioLogin();
    let IdCliente = User.logeado ? User.IdCliente : 0;

    let req = await CitaStore.guardarCita({
      IdCliente: IdCliente,
      IdVehiculo: this.props.idVehiculo,
      IdTipoMantenimiento: this.state.idTipoMantenimiento,
      IdTurno: this.state.idTurno,
      Kilometraje: this.state.Kilometraje,
      FechaCita: moment(this.state.fecha).format('YYYY-DD-MM'),
    });

    if (req.exito) {
      this.props.cargarVehiculos();
      this.setState(this.baseState)
      //this.setState({ modal: false });
    }
  }

  render() {
    const { visible, } = this.props;
    const { modal, } = this.state;

    return (
      <>
        <Button disabled={this.props.tieneReservas} color="success" onClick={() => this.cargarModal()}>
          <Icon icon={faCalendarPlus}></Icon>
        </Button>
        <Modal size='md' isOpen={modal} unmountOnClose={true} backdrop='static' keyboard={false} autoFocus={false}>
          <ModalHeader className='ModalHeader' toggle={() => this.setState(this.baseState)}>
            {'Agregar Cita de Vehiculo ' + this.props.placa}
          </ModalHeader>
          <ModalBody className='ModalBody'>
            <Container fluid>
              <FormGroup>
                <Label >Fecha de Cita</Label>
                <DatePicker
                  locale="es"
                  //date={this.state.fecha}
                  defaultDate={this.state.fecha}
                  onChange={e => this.cambiarFecha(e)}
                  daysOfWeekDisabled={[0]}
                  minDate={this.state.fechaMin}
                  readOnly={this.state.procesando}
                />
                {/* <DatePicker
                    style={{ width: '120px', }}
                    dayLabels={['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']}
                    monthLabels={['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']}
                    minDate={moment().subtract(-2, 'days').toISOString(true)}
                    //maxDate={moment().toISOString(true)}
                    showClearButton={true}
                    daysDisabled={[0, 1]}
                  /> */}
              </FormGroup>
              <FormGroup >
                <Label>Tipo Matenimiento:</Label>
                <Input
                  disabled={this.state.procesando}
                  type="select"
                  onChange={(e) => this.setState({ idTipoMantenimiento: e.target.value })}>
                  {
                    _.map(this.state.lstTipoMante, (obj, index) => (
                      <option value={obj.IdTipoMantenimiento} >{obj.Descripcion}</option>
                    ))
                  }
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>Horario: </Label>
                <Input
                  disabled={this.state.procesando}
                  type="select"
                  onChange={(e) => this.setState({ idTurno: e.target.value })}>
                  {
                    _.map(this.state.lstTurnos, (obj, index) => (
                      <option value={obj.IdTurno} >{obj.Descripcion}</option>
                    ))
                  }
                </Input>
              </FormGroup>
              <FormGroup >
                <Label>Kilometraje:</Label>
                <Input
                  disabled={this.state.procesando}
                  type="text"
                  onChange={(e) => this.setState({ Kilometraje: e.target.value })}>

                </Input>
              </FormGroup>
            </Container>
          </ModalBody>
          <ModalFooter className='ModalFooter'>
            <div className='m-0 clearfix-widthfull '>
              <CustomButton
                className='float-letf'
                color='secondary'
                icon={faTimesCircle}
                text='Cancelar'
                disabled={this.state.procesando}
                onClick={() => this.setState(this.baseState)}
              />
              <CustomButton
                className='float-right'
                color='danger'
                icon={faCheckCircle}
                text='Grabar Cita'
                loading={this.state.procesando}
                disabled={this.state.procesando}
                onClick={() => this.procesar()}
              />
            </div>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}