import React from 'react';
import {
	Container
} from 'reactstrap';

export default class Content extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<>


				<div className='content-wrapper'>
					{/* Content Header (Page header) */}
					<div className="content-header">
						<div className="container-fluid">
							<div className="row mb-2">
								<div className="col-sm-12">
									<h1 className="m-0">{this.props.titlePage}</h1>
								</div>{/* /.col */}

							</div>{/* /.row */}
						</div>{/* /.container-fluid */}
					</div>
					{/* /.content-header */}
					<Container fluid>
						{this.props.children}
					</Container>
				</div>
				<footer className='main-footer'></footer>
			</>
		);
	}
}