import { Peticion, } from '../helpers/common/index';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export default class CitaStore {

	static getTiposMantenimientos() {
		const req = new Peticion();
		return req.get(
			`${API_BASE_URL}api/cita/tiposMantenimientos`
		);
	}

	static getTurnosXFecha(fecha) {
		const req = new Peticion();
		return req.get(
			`${API_BASE_URL}api/cita/turnosXFecha/?fecha=${fecha}`
		);
	}

	static getCitasXCliente(IdCliente) {
		const req = new Peticion();
		return req.get(
			`${API_BASE_URL}api/cita/citasXCliente/?IdCliente=${IdCliente}`
		);
	}

	static getCitasAsesor() {
		const req = new Peticion();
		return req.get(
			`${API_BASE_URL}api/cita/citasAsesor/`
		);
	}

	static guardarCita(obj) {
		const req = new Peticion();
		return req.post(
			`${API_BASE_URL}api/cita/nuevaCita/`,
			obj
		);
	}

	static cancelarCita(obj) {
    const req = new Peticion();
    return req.post(
      `${API_BASE_URL}api/cita/cancelarCita/`,
      obj
    );
  }

	static confirmarCita(obj) {
    const req = new Peticion();
    return req.post(
      `${API_BASE_URL}api/cita/confirmarCita/`,
      obj
    );
  }
}