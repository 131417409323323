import _ from 'lodash';

export function getUsuarioLogin()
{
    let Usuario = {};
    Usuario.logeado = false;
    Usuario.display = '';

    let user = localStorage.getItem('usuario');   
    if(!_.isNull(user)){
      Usuario = JSON.parse(user);
      Usuario.logeado = true;
      Usuario.display = Usuario.RazonSocial === '' ? Usuario.Nombres + ' ' + Usuario.Apellidos : Usuario.RazonSocial;
    }
    return Usuario;
}