import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Label, Row } from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus, faCar, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Content, Header, Menu, Footer } from '../base';
import CardCita from './cardCita';
import { CitaStore } from '../../stores';
import moment from 'moment-timezone';
import _ from 'lodash';
import { getUsuarioLogin } from '../../helpers/common';
import { LoaderFull } from '../../helpers/ui';


export default class ListaVehiculos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lstCitas: [],
      iniciando: false,
    };

    this.baseState = this.state;
  }

  componentDidMount() {

    this.cargarCitas();
  }

  async cargarCitas() {
    this.setState({ iniciando: true });
    let User = getUsuarioLogin();
    let IdCliente = User.logeado ? User.IdCliente : 0;

    let req = await CitaStore.getCitasXCliente(IdCliente);
    if (req.exito) {
      this.setState({ lstCitas: req.datos, iniciando: false })
    }
  }

  clickCitas(id, placa) {
    alert(placa);
  }

  clickInfo(id, placa) {

  }

  render() {
    return (
      <>
        <Header ></Header>
        <Menu idOpcion='0002'></Menu>
        <Content titlePage={'Mis Citas'}>
          {
            (this.state.iniciando) ?
              <LoaderFull text='Cargando citas...' />
              :
              <Row>
                {
                  _.map(this.state.lstCitas, (obj, index) => (
                    <>
                      <CardCita
                        placa={obj.PlacaVh}
                        idCita={obj.IdCita}
                        fecha={moment(obj.FechaCita).format('DD/MM/YYYY')}
                        turno={obj.Turno}
                        callBack={() => this.cargarCitas()}
                        cita={(id, placa) => this.clickCitas(id, placa)}
                        informacion={(id, placa) => this.clickInfo(id, placa)}
                      />
                    </>
                  ))
                }
              </Row>
          }
        </Content>
      </>
    );
  }
}
